@use 'ui/styles/variables' as vars;

.PageContainer {
  width: 100%;
  margin: 0 auto;

  .content {
    max-width: 1280px;
    margin: 0 auto;
  }

  .mainCTA {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -32px;
  }

  .top {
    padding: 16px 0;
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .odometer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;

      h2 {
        font-size: 24px;
        color: #000;
        margin: 0;
        line-height: 2rem;
      }
    }
  }

  .grid {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;

    .column {
      display: flex;
      flex-direction: column;
      width: 33%;
      gap: 16px;
    }
  }

  .chevron {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 40px;
    padding: 48px 16px 40px 16px;

    position: relative;
    height: fit-content;


    @media (min-width: 1280px) {
      padding-left: calc((100vw - 1280px) /2);
      padding-right: calc((100vw - 1280px) /2);
    }

    @include vars.mobileOnly() {
      flex-direction: column;
      align-items: center;

      .img {
        top: 0px !important;
      }
    }

    .background {
      --s: 95%;
      position: absolute;
      z-index: -1;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: var(--background-lightBlue);
      clip-path: polygon(0 0, 0 var(--s), 50% 100%, 100% var(--s), 100% 0, 50% calc(100% - var(--s)));
    }

    .text {
      height: fit-content;
      padding-top: 24px;
      padding-left: 16px;
    }

    .img {
      position: relative;
      top: -80px;
      z-index: 2;
    }

    .topChevron {
      position: absolute;
      top: -1px;
      max-width: 100%;
      left: 0px;
      z-index: 1;
      max-height: 40px;
    }
  }

  .about {
    max-width: 768px;
    width: 75%;
    margin: 0 auto;
    text-align: center;
    padding: 16px;
    text-wrap: pretty;
    text-justify: newspaper;

    @include vars.mobileOnly() {
      width: 100%;
    }
  }

  @include vars.tabletOnly() {
    .top {
      width: 100%;
    }

    .grid {
      flex-wrap: wrap;

      .column {
        width: calc(50% - 8px);

        &:last-of-type {
          width: 100%;
          flex-direction: row;
          gap: 16px;
          flex-wrap: wrap;

          &>div {
            width: calc(50% - 8px);
            height: fit-content;
          }
        }
      }
    }
  }

  @include vars.mobileOnly() {
    .top {
      width: 100%;
      padding: 16px;

      .odometer {
        flex-direction: column;
        align-items: center;
        gap: 4px;

        h2 {
          font-size: 1.25rem;
          line-height: 1.25rem;
        }
      }
    }

    .grid {
      flex-direction: column;

      .column {
        width: 100%;

      }
    }
  }

}