$screenSize: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin mediaQuery($size) {
  @media (min-width: map-get($screenSize, $size)) {
    @content;
  }
}

@mixin maxSize($size) {
  @media (max-width: (map-get($screenSize, $size) - 0.02px)) {
    @content;
  }
}

@mixin mobileOnly() {
  @media (max-width: (map-get($screenSize, "md") - 0.02px)) {
    @content;
  }
}

@mixin tabletOnly() {
  @media (max-width: (map-get($screenSize, "lg") - 0.02px)) and (min-width: map-get($screenSize, "md")) {
    @content;
  }
}